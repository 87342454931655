import { useMediaQuery, useTheme } from '@mui/material';

import styles from '../../components/common/not-found/notFound.module.scss';
import logo from '../../assets/images/notFound.png';
import { Button } from '../../components/base';


const OfflineDownloadApp = () => {

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  // console.log(isMobileView)

  const handleClick = () => {
    window.open('https://mitsu.app.link/WGuSNn9b8Ab');
  };
  return (
    <div className={styles.container}>
      <img src={logo} alt="404" className={styles.img} />
      <div className={styles['msg']}>
        <p className={styles.link_text}>
          Oops! This page isn’t available for you.
        </p>
      </div>
      <div style={{height:'40px'}} />
      <a
        target="_blank"
        rel="noreferrer"
        href="https://wa.me/9987806854?text=Hello,%20I%20am%20interested%20in%20Mitsu%27s%20program.%20How%20can%20I%20learn%20more"
      >
        <Button variant="contained" >
          Get support
        </Button>
      </a>
    </div>
  );
};

export default OfflineDownloadApp;

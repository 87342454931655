import CallScheduledPage, { ScheduleType } from '../pages/callSchedule/callScheduled.page';
import { IRoutesProps } from './index';

import Landing from '../pages';
import AboutUs from '../pages/about-mitsu';
import ScheduleCallPage from '../pages/callSchedule/scheduleCall.page';
import TherapistDetailsPage from '../pages/callSchedule/therapistDetails.page';
import EmailVerification from '../pages/email-verification';
import EnrollInProgram from '../pages/enroll-program/index';
import Login from '../pages/login/index';
import OnBoardingDashboard from '../pages/onboarding/index';
import PersonalDetails from '../pages/personal-details/index';
import Register from '../pages/register';
import CheckinSummary from '../pages/selfCheckIn/checkInSummary.page';
import CheckInBasicDetailsPage from '../pages/selfCheckIn/selfCheckInBasicDetails.page';
import CheckInInstructionsPage from '../pages/selfCheckIn/selfCheckinInstruction.page';
import SelfCheckinQuestionaire from '../pages/selfCheckIn/selfCheckinQuestionaire.page';
import CallExplainerScreen from '../pages/selfCheckIn/callExplainerScreen';

import OrderStatusPage from '../pages/selfCheckIn/orderStatus';
import PaymentPendingPage from '../pages/payment-pending/index';
import PaymentReceivedOffline from '../pages/enroll-program/payment_received_offline';
import OfflineDownloadApp from '../pages/enroll-program/download_app_offline';


export const CLIENT_ROUTES = {
  register: '/',
  otp_confirmation: 'otp-confirmation',
  personal_details: 'personal-details',
  email_sent: 'email-sent',
  email_verification: 'email-verification',
  onboarding: 'onboarding',
  dashboard: 'dashboard',
  about_mitsu: 'learn-about-mitsu',
  mood_check_in: 'mood-check-in',
  mood_check_in_instructions: 'mood-check-in-instructions',
  mood_check_in_details: 'user-details',
  mood_check_in_questionaire: 'check-in-questionaire',
  mood_check_in_summary: 'mood-check-in-summary',
  setup_free_call: 'setup_free_call',
  scheduled_call: 'scheduled_call',
  call_confirmation: 'call_confirmation',
  call_rescheduled: 'call_rescheduled',
  call_cancelled: 'call_cancelled',
  get_to_know: 'get_to_know',
  error: 'error',
  enroll: 'enroll-in-program',
  order_status: 'order_status',
  call_explainer: 'call_explainer',

  payment_processing: 'payment_processing',
  payment_failed: 'payment_failed',
  offline_payment_redirect: 'payment_redirect',
  download_app: 'download_app',
};

export const protectedRoutes = [
  {
    path: CLIENT_ROUTES.register,
    element: <Landing />,
    children: [
      {
        path: CLIENT_ROUTES.register,
        element: <Register />,
      },
      {
        path: CLIENT_ROUTES.otp_confirmation,
        element: <Login />,
      },
      {
        path: CLIENT_ROUTES.personal_details,
        element: <PersonalDetails />,
      },
      {
        path: CLIENT_ROUTES.offline_payment_redirect,
        element: <PaymentReceivedOffline/>
      },
      {
        path: CLIENT_ROUTES.download_app,
        element: <OfflineDownloadApp/>
      }
    ],
  },
] as IRoutesProps[];

export const privateRoutes = [
  {
    path: CLIENT_ROUTES.onboarding,
    element: <Landing />,
    children: [
      {
        path: CLIENT_ROUTES.email_sent,
        element: <EmailVerification isForced={false} />,
      },
      {
        path: CLIENT_ROUTES.email_verification,
        element: <EmailVerification isForced />,
      },
      {
        index: true,
        path: CLIENT_ROUTES.dashboard,
        element: <OnBoardingDashboard />,
      },
      {
        path: CLIENT_ROUTES.about_mitsu,
        element: <AboutUs />,
      },
      {
        path: CLIENT_ROUTES.mood_check_in,
        element: <Landing />,
        children: [
          {
            path: CLIENT_ROUTES.mood_check_in_instructions,
            element: <CheckInInstructionsPage />,
          },
          {
            path: `${CLIENT_ROUTES.mood_check_in_questionaire}/*`,
            element: <SelfCheckinQuestionaire />,
          },
          {
            path: CLIENT_ROUTES.mood_check_in_summary,
            element: <CheckinSummary />,
          },
        ],
      },
      {
        path: CLIENT_ROUTES.order_status,
        element: <OrderStatusPage />
      },
      {
        path: CLIENT_ROUTES.setup_free_call,
        element: <Landing />,
        children: [
          {
            path: CLIENT_ROUTES.mood_check_in_details,
            element: <CheckInBasicDetailsPage />,
          },
          {
            path: CLIENT_ROUTES.scheduled_call,
            element: <ScheduleCallPage />,
          },
          {
            path: CLIENT_ROUTES.call_confirmation,
            element: <CallScheduledPage scheduleType={ScheduleType.FirstTime} />,
          },
          {
            path: CLIENT_ROUTES.call_rescheduled,
            element: <CallScheduledPage scheduleType={ScheduleType.Reschedule} />,
          },
          {
            path: CLIENT_ROUTES.call_cancelled,
            element: <CallScheduledPage scheduleType={ScheduleType.Cancelled} />,
          },
          {
            path: CLIENT_ROUTES.get_to_know,
            element: <TherapistDetailsPage />,
          },
          {
            path: CLIENT_ROUTES.call_explainer,
            element: <CallExplainerScreen />
          },
          {
            path: CLIENT_ROUTES.payment_processing,
            element: <PaymentPendingPage isFailed={false} />
          },
          {
            path: CLIENT_ROUTES.payment_failed,
            element: <PaymentPendingPage isFailed={true} />
          }
        ],
      },
      {
        path: CLIENT_ROUTES.enroll,
        element: <EnrollInProgram />,
      },
    ],
  },
] as IRoutesProps[];

const routes = {
  private: privateRoutes,
  protected: protectedRoutes,
};

export default routes;

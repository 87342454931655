import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { CLIENT_ROUTES } from './routes';
import { useEffect, useState } from 'react';
import { getUserDetails } from '../services/selfcheckin.service';

function ProtectedRoute() {
  const location = useLocation();
  const { from } = location.state || {};
  const userId = localStorage.getItem('userId');
  const accessToken = localStorage.getItem('accessToken');
  const isOfflineUser =  localStorage.getItem('isOfflineUser') == 'true';

  if(isOfflineUser &&  window.location.pathname != `/${CLIENT_ROUTES.download_app}`){
    return <Navigate to={`/${CLIENT_ROUTES.download_app}`} replace/>;
  }
  if (!isOfflineUser && accessToken && userId) {
    if (window.location.pathname == `/${CLIENT_ROUTES.personal_details}`) {
      return <Outlet />
    } else {
      const redirectURL = from?.pathname ? from.pathname : `${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.dashboard}`;
      // console.log(sessionStorage.getItem('initialRoute'))
      return <Navigate to={redirectURL} replace />;
    }
  }
  return <Outlet />;
}

export default ProtectedRoute;

import { Navigate, Outlet } from 'react-router-dom';
import { CLIENT_ROUTES } from './routes';
import { useEffect, useState } from 'react';
import { getUserDetails } from '../services/selfcheckin.service';

function PrivateRoutes() {
  const mobileNumber = localStorage.getItem('mobile');
  const accessToken = localStorage.getItem('accessToken');
  const [isEmailVerified, setIsEmailVerified] = useState(true);

  const [isOfflineUser,setOfflineUser] = useState(false)

  useEffect(
    () => {
      const getIsEmailVerified = async () => {
        const details = await getUserDetails()
        try {
          if (details.isEmailVerified) {
            localStorage.setItem('isEmailVerified', 'true');
            setIsEmailVerified(true)
          } else {
            localStorage.setItem('isEmailVerified', 'false');
            setIsEmailVerified(false)
          }
          if(details.isOfflineUser){
            localStorage.setItem('isOfflineUser', 'true');

            setOfflineUser(true)
          }
        } catch (error) {
          //ignore
        }
      }
      getIsEmailVerified()
    }, [accessToken]
  );

  if(isOfflineUser){
    return <Navigate to={`/${CLIENT_ROUTES.download_app}`} replace/>;
  }

  if (!mobileNumber || !accessToken) {
    sessionStorage.setItem('initialRoute', window.location.href);
    // console.log('initial Route ' + window.location.href)
    return <Navigate to={`${CLIENT_ROUTES.register}`} replace />;
  }

  if (!isEmailVerified) {
    return <Navigate to={`${CLIENT_ROUTES.personal_details}`} replace />
  }

  return <Outlet />;
}

export default PrivateRoutes;

import { useMediaQuery, useTheme } from '@mui/material';
import Image1 from '../../assets/images/412shots_so 4.png';
import Image2 from '../../assets/images/502shots_so 1.png';
import { AdaptiveTypography } from '../../components/base';
import Button from '../../components/base/button';
import Typography from '../../components/base/typography';
import { Seo } from '../../components/common';
import css from './index.module.scss';
import { useSearchParam } from 'react-use';

const PaymentReceivedOffline = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  // console.log(isMobileView)

  const orderId =  useSearchParam("order_id")
  const status =  useSearchParam("status")

  const isSuccess = status == "CHARGED"

  const isFailed = status == "AUTHENTICATION_FAILED" || status == "AUTHORIZATION_FAILED" || status == "JUSPAY_DECLINED"



  return (
    <>
      <div className={css.container}>
        <Seo title="Download the App now" />
        <section className={css.layout}>
          <div className={css.titleWrapper}>
            <Typography variant="h1">
              <span className={!isMobileView ? css.size40 : ''}>
                {
                  isSuccess? "Thank you for choosing Mitsu!"
                  :isFailed? "Payment unsuccessful" : "Payment In Process"
                }
              </span>
            </Typography>
            <Typography variant="h2mid">
              {
                isSuccess? "Your payment has been received successfully."
                :isFailed? "Unfortunately, your payment could not be processed." : "Your payment is being processed. Please allow us some time for confirmation."
              }
              
            </Typography>
            <div style={{ height: '8px' }} />


            <Typography variant="h5">
              Payment details:
            </Typography>
            <div className={css.orderInfo}>
              <Typography variant="h2mid">
                <span >Order Id: {orderId}  </span>
              </Typography>

              <Typography variant="h2mid">
                <span >Status: {status}  </span>
              </Typography>
            </div>
            <div style={{ height: '8px' }} />
            {
              !isSuccess &&  <AdaptiveTypography variant="subheading1" mobileVariant="inherit">
              
              {
                isFailed? "Please try again or contact our support team if you need assistance.":
                "If you do not receive a confirmation, please feel free to contact our support team."
              }
            </AdaptiveTypography>
            }
          </div>
          {isMobileView && (
            <section className={css.btnContainer}>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9987806854?text=Hello,%20I%20am%20interested%20in%20Mitsu%27s%20program.%20How%20can%20I%20learn%20more"
              >
                <Button variant="contained" >
                  Get support
                </Button>
              </a>
            </section>
          )}
        </section>
        {!isMobileView && (
          <section className={css.btnContainer}>
            <a
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/9987806854?text=Hello,%20I%20am%20interested%20in%20Mitsu%27s%20program.%20How%20can%20I%20learn%20more"
          >
            <Button variant="contained" >
              Get support
            </Button>
            </a>
          </section>
        )}
      </div>
    </>
  );
};

export default PaymentReceivedOffline;
